import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Dimmer, Grid, Header, Icon, Label, List, Loader, Message, Segment, Table } from "semantic-ui-react";
import { mutate } from "swr";
import { changeStatusCliente, changeUsername, useBackupsCliente, useCliente } from "../../backend";
import { formataData, formataTelefone, humanFileSize } from "../../helper";
import Cliente from "../../types/models/Cliente";
import { StatusChangeType } from "../../types/StatusChangeRequest";
import ChangeUsernameDialog from "../dialogs/ChangeUsernameDialog";
import EditClienteDialog from "../dialogs/EditClienteDialog";
import StatusChangeDialog from "../dialogs/StatusChangeDialog";
import StatusCliente from "../widgets/StatusCliente";
import UltimoBackupCliente from "../widgets/UltimoBackupCliente";
import UltimoPingCliente from "../widgets/UltimoPingCliente";
import VersaoDataGuardCliente from "../widgets/VersaoDataGuardCliente";
import { VersaoDbmsCliente } from "../widgets/VersaoDbmsCliente";

const ClienteDetail = () => {
  const params = useParams();
  const cliData = useCliente(Number(params.id));
  const bkpData = useBackupsCliente(Number(params.id));
  const [showStatusChangeDialog, setShowStatusChangeDialog] = useState(false);
  const [statusChangeOperation, setStatusChangeOperation] = useState(0);
  const [showEditUsernameDialog, setShowEditUsernameDialog] = useState(false);
  const [showEditClienteDialog, setShowEditClienteDialog] = useState(false);
  
  const renderDialogs = () => {
    if (!cliData.data) return null;
    return (
      <>
        <EditClienteDialog show={showEditClienteDialog} cliente={cliData.data} handleClose={closeEditClienteCallback} />
        <ChangeUsernameDialog show={showEditUsernameDialog} handleClose={closeEudCallback} />
        <StatusChangeDialog show={showStatusChangeDialog} operacao={statusChangeOperation} handleClose={closeStatusChangeCallback}>
          <List>
            <List.Header as='h4'>Dados do cliente</List.Header>
              <List.Item>{cliData.data?.id}</List.Item>
              <List.Item>{cliData.data?.nome}</List.Item>
              <List.Item>{cliData.data?.razaoSocial}</List.Item>
              <List.Item>{cliData.data?.contato}</List.Item>
              <List.Item>{cliData.data?.observacoes}</List.Item>
          </List>
        </StatusChangeDialog>
      </>
    );
  }

  const closeEudCallback = async (newUsername: string | null) => {
    setShowEditUsernameDialog(false);
    if (newUsername && newUsername !== username) {
      const resp = await changeUsername(Number(cliData.data?.id), newUsername);
      mutate(`/cliente/${resp?.id}`);
    }
  }

  const closeStatusChangeCallback = async (operacao: StatusChangeType, motivo: string | null) => {
    setShowStatusChangeDialog(false);
    if (motivo && motivo.length > 1) {
      await changeStatusCliente(
      {
        type: operacao,
        modelId: cliData.data?.id ?? 0,
        message: motivo
      }
      );
      mutate(`/cliente/${cliData.data?.id}`);
    }
  };

  const closeEditClienteCallback = (cli: Cliente | null) => {
    if (cli) {
      mutate(`/cliente/${cli.id}`);
    }
    setShowEditClienteDialog(false);
  }
  
  const renderTableLoading = () => {
    return (
      <Grid container textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
        <Grid.Column>
          <Dimmer active inverted>
            <Loader size="massive" active></Loader>
          </Dimmer>
          <Message size="massive">{' '}</Message>
          <Message size="massive">Buscando dados do cliente no servidor do DataGuard</Message>
        </Grid.Column>
      </Grid>  
    )
  };

  const renderTableError = () => {
    return (
    <Grid container textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
      <Grid.Column>
        <Icon name='dont' color="red" size="massive" />
        <Header as='h1'>Ocorreu um erro ao buscar os dados do cliente no servidor</Header>
        <Message color='red'>Por favor atualize a página e tente novamente</Message>
      </Grid.Column>
    </Grid>
    );
  };

  const renderEditButton = () => {
    return <Button color='blue' onClick={() => setShowEditClienteDialog(true)}><Icon name='edit' />Editar</Button>;
  }

  const renderAtivaInativaButton = () => {
    if (cliData.data?.ativo) return <Button color='orange' onClick={() => showStatusChange(1)}><Icon name='cancel' />Inativar</Button>
    else return <Button color='green' onClick={() => showStatusChange(0)}><Icon name='undo' />Reativar</Button>
  }

  const renderExcluiRestauraButton = () => {
    if (cliData.data?.deletedById && cliData.data.deletedById > 0) return <Button color='green' onClick={() => showStatusChange(2)}><Icon name='undo' />Restaurar</Button>
    else return <Button color='red' onClick={() => showStatusChange(3)}><Icon name='trash' />Excluir</Button>
  }

  const showStatusChange = (opr: number) => {
    setStatusChangeOperation(opr);
    setShowStatusChangeDialog(true);
  }

  const modalChangeUsername = () => {
    setShowEditUsernameDialog(true);
  };

  let username = cliData.data?.username ?? '';

  const renderDadosCliente = () => {
    return (
      <Segment>
        <Grid columns={3}>
          <Grid.Column width={1}>
            <Label attached="top left" color='black' >ID {cliData.data?.id}</Label>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button as='div' labelPosition="left">
              <Label as='a' basic pointing='right'>{username}</Label>
              <Button icon='edit' color='blue' onClick={modalChangeUsername} />
            </Button>
          </Grid.Column>
          <Grid.Column width={12} textAlign="right">
            <Button.Group>
              {renderEditButton()}
              {renderAtivaInativaButton()}
              {renderExcluiRestauraButton()}
            </Button.Group>
          </Grid.Column>
        </Grid>
        <Grid columns={3}>
          <Grid.Column width={1} />
          <Grid.Column width={9}>
            <Header as='h3'>{cliData.data?.nome}</Header>
            {cliData.data?.razaoSocial}
          </Grid.Column>
          <Grid.Column width={6}>
            {formataTelefone(cliData.data?.celular ?? '') + ' (' + cliData.data?.contato + ')'} <br />
            {cliData.data?.email}
          </Grid.Column>
        </Grid>
      </Segment>
    )
  };

  const renderTableBackups = () => {
    if (!cliData || cliData.isError || !bkpData || bkpData.isError) return renderTableError();
    if (cliData.isLoading || bkpData.isLoading) return renderTableLoading();

    return (
      <Grid>
        <Table padded compact striped basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data / Hora</Table.HeaderCell>
              <Table.HeaderCell>Nome do arquivo</Table.HeaderCell>
              <Table.HeaderCell>Tamanho</Table.HeaderCell>
              <Table.HeaderCell>{''}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bkpData.data?.map((bkp) => {
              return (
                <Table.Row key={bkp.id}>
                  <Table.Cell>{formataData(bkp.data)}</Table.Cell>
                  <Table.Cell>{bkp.filename}</Table.Cell>
                  <Table.Cell>{humanFileSize(bkp.size)}</Table.Cell>
                  <Table.Cell textAlign="right"><a href={`https://dataguard.experttecnologia.com/download/${cliData.data?.username}/${bkp.filename}`} ><Icon name='download' /></a></Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Grid>
    );
  }

  const renderTotais = () => {
    return (
      <Grid>
        <Grid.Column>
          <Label as='h2'>{bkpData.data?.length} backups no servidor</Label>
        </Grid.Column>
      </Grid>
    );
  };

  return (
    <Container fluid>
      <Grid.Row>
        <Grid columns={5} padded='horizontally'>
          <StatusCliente cliente={cliData.data} />
          <VersaoDataGuardCliente cliente={cliData.data} />
          <VersaoDbmsCliente cliente={cliData.data} />
          <UltimoBackupCliente cliente={cliData.data} />
          <UltimoPingCliente cliente={cliData.data} />
        </Grid>
      </Grid.Row>
      <Grid.Row>
        <Grid columns={2} padded>
          <Grid.Column width={12}>{renderDadosCliente()}</Grid.Column>
          <Grid.Column width={4}>
            <Segment style={{ height: '100%' }}>
              <Label as='a' color='teal' ribbon>Observações</Label>
              <Message>{cliData.data?.observacoes}</Message>
            </Segment>
          </Grid.Column>
        </Grid>
      </Grid.Row>
      <Grid.Row>
        <Grid columns={1} padded='horizontally'>
          <Grid.Column>
          <Segment>
            {renderTableBackups()}
            {renderTotais()}
          </Segment>
          </Grid.Column>
        </Grid>
      </Grid.Row>
      {renderDialogs()}
    </Container>
  );
};

export default ClienteDetail;