import { Grid, Segment, Statistic } from "semantic-ui-react";
import { formataVersaoDbmsAbreviada } from "../../helper";
import Cliente from "../../types/models/Cliente";

export function VersaoDbmsCliente(props: { cliente: Cliente | undefined }) {
  if (!props.cliente) return null;
  
  let color: ('teal' | 'orange' | 'red' | 'purple' | 'blue' ) = 'red';
  let text = 'DESCONHECIDA';
  let numeros = '';
  
  if (props.cliente !== undefined){
    if (props.cliente.versaoDbms) {
      numeros = props.cliente.versaoDbms.length > 10 ? props.cliente.versaoDbms.substring(0, 9) : props.cliente.versaoDbms;
      if (props.cliente.versaoDbms.startsWith("10.0")) color = 'purple';
      else if (props.cliente.versaoDbms.startsWith("10.50")) color = 'purple';
      else if (props.cliente.versaoDbms.startsWith("11.0")) color = 'orange';
      else if (props.cliente.versaoDbms.startsWith("12.0")) color = 'orange';
      else if (props.cliente.versaoDbms.startsWith("13.0")) color = 'blue';
      else if (props.cliente.versaoDbms.startsWith("14.0")) color = 'blue';
      else if (props.cliente.versaoDbms.startsWith("15.0")) color = 'teal';
      else if (props.cliente.versaoDbms.startsWith("16.0")) color = 'teal';
      text = formataVersaoDbmsAbreviada(props.cliente.versaoDbms);
    }
  }

  if (props.cliente.versaoDbms?.endsWith("E")) numeros = numeros + ' EXPRESS';
  else if (props.cliente.versaoDbms?.endsWith("F")) numeros = numeros + ' FULL';
  else numeros = 'VERSÃO SGBD';
  
  return (
    <Grid.Column textAlign="center">
      <Segment>
      <Statistic color={color} size="mini">
        <Statistic.Value>{text}</Statistic.Value>
        <Statistic.Label>{numeros}</Statistic.Label>
      </Statistic>
      </Segment>
    </Grid.Column>
  );
}