import Cookies from 'js-cookie';
import { MouseEvent, useState } from 'react';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import { authenticate } from '../backend';
import useAuthStore from '../store/store';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showErro, setShowErro] = useState(false);
  const setUserId = useAuthStore((state) => state.setUserId);
  const setUserName = useAuthStore((state) => state.setUserName);
  const setApiToken = useAuthStore((state) => state.setApiToken);
  const setSessionLength = useAuthStore((state) => state.setSessionLength);

  const onSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    
    const auth = await authenticate(username, password);
    if (auth) {
      setUserId(auth.id);
      setUserName(auth.name);
      setApiToken(auth.jwtToken);
      setSessionLength(auth.sessionLength);
      Cookies.set('apiToken', auth.jwtToken);
    } else {
      setShowErro(true);
    }
  };

  const showInvalidCredentials = () => {
    if (showErro) {
      return (
        <Message error textAlign='start'>
        <Message.List>
          <Message.Item>
            Nome de usuário ou senha inválidos
          </Message.Item>
        </Message.List>
      </Message>
      );
    } else return null;
  }

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image width={128} centered src='/dataguard-white.png' />
        <Header as='h2' color='teal' textAlign='center'>
          DataGuard Web
        </Header>
        <Form size='large' error={showErro}>
          <Segment stacked>
            <Form.Input icon='user' error={showErro} required onChange={(e) => setUsername(e.target.value)} iconPosition='left' placeholder='Nome de usuário' />
            <Form.Input icon='lock' error={showErro} required onChange={(e) => setPassword(e.target.value)} iconPosition='left' placeholder='Senha' type='password' />
            <Button color='teal' onClick={onSubmit} fluid size='large'>
              Login
            </Button>
          </Segment>
        </Form>
        {showInvalidCredentials()}
        <Message>
          <Header as='h6'>O DataGuard Web é parte das soluções da <a href="https://www.experttecnologia.com">Expert Tecnologia</a></Header>
        </Message>
        <Grid.Column>
          <Header as='h6'>
            &copy; 2022 - Expert Tecnologia
          </Header>
        </Grid.Column>
      </Grid.Column>
    </Grid>
  )
};

export default Login;