import { Button, Grid, Label, Segment } from "semantic-ui-react";
import { useDataguardVersoes, useMapaDataguardVersoes } from "../../backend";

const MapaVersoesDgw = () => {
  const dgwMap = useMapaDataguardVersoes();
  const versoes = useDataguardVersoes();

  let versOk = ''; let versWarn = '';
  if (versoes.data && versoes.data?.length > 1) {
    versOk = versoes.data[0]; versWarn = versoes.data[1];
  }
  
  let dataVrsDgw: {x: string, y: any}[] = [];
  dgwMap.data?.mapa.forEach(m => {
    dataVrsDgw.push({ x: m.v === '0.0.0000' ? 'Desconhecida' : m.v, y: m.c });
  });

  return (
    <Grid.Column width={5} stretched textAlign="center">
      <Segment textAlign="center">
        {dataVrsDgw.map((v: {x:string, y:any}) => {
          return (
            <Button key={v.x} as='div' labelPosition='right'>
                <Button size="tiny" color={v.x === versOk ? 'teal' : v.x === versWarn ? 'orange' : 'red'}>{v.x}</Button>
                <Label size="tiny" color={v.x === versOk ? 'teal' : v.x === versWarn ? 'orange' : 'red'} basic pointing='left'>{v.y}</Label>
            </Button>
          )
        })}
      </Segment>
    </Grid.Column>
  )
};

export default MapaVersoesDgw; 