import Cookies from "js-cookie";
import { useEffect } from "react";
import { Navigate, } from "react-router-dom";
import { validToken } from "../backend";
import useAuthStore from "../store/store";

const GuardedRoute = (props: { children : JSX.Element } ) : JSX.Element => {
  const setUserId = useAuthStore((state) => state.setUserId);
  const setUserName = useAuthStore((state) => state.setUserName);
  const setApiToken = useAuthStore((state) => state.setApiToken);
  const setSessionLength = useAuthStore((state) => state.setSessionLength);
  
  
  useEffect(() => {
    const checkToken = async () => {
        const res = await validToken();
        if (!res) {
            setUserId(0);
            setUserName('');
            setApiToken('');
            setSessionLength(0);
            Cookies.set('apiToken', '');
            return <Navigate to='/' />
        }
    }

    checkToken();
  })

  return props.children;
};

export default GuardedRoute;