import { useEffect, useState } from "react";
import { Button, Input, Message, Modal } from "semantic-ui-react";
import { validateUsername } from "../../backend";

const ChangeUsernameDialog = (props: { show: boolean, handleClose: (u: string | null) => void }) => {
  const [username, setUsername] = useState('');
  const [debouncedUsername, setDebouncedUsername] = useState('');
  const [usernameValidation, setUsernameValidation] = useState(0);
  const [usernameValidationMessage, setUsernameValidationMessage] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (username !== debouncedUsername) setDebouncedUsername(username);
    }, 500);

    return () => clearInterval(intervalId);
  })

  useEffect(() => {
    if (debouncedUsername.length > 0) {
      validaUsername(debouncedUsername);
    }
  }, [debouncedUsername]);

  const handleClose = () => {
    props.handleClose(null);
    setUsername('');
    setUsernameValidation(0);
    setUsernameValidationMessage('');
  };
  
  const validaUsername = async (u: string) => {
    if (!u) {
      setUsername(u);
      setUsernameValidation(1);
      setUsernameValidationMessage('O nome de usuário é obrigatório');
      return;
    }
    if (u.length < 3) {
      setUsername(u);
      setUsernameValidation(1);
      setUsernameValidationMessage('O nome de usuário deve ter ao menos 3 caracteres');
      return;
    }
    
    const srv = await validateUsername(u);
    if (srv) {
      setUsername(u);
      setUsernameValidation(srv.resposta);
      setUsernameValidationMessage(srv.mensagem);
    }
  };
  
  const changeUsername = async () => {
    await (validaUsername(username));
    
    if (usernameValidation === 2) {
      props.handleClose(username);
      setUsername('');
      setUsernameValidation(0);
      setUsernameValidationMessage('');
    }
  };

  const renderMessageOk = () => (usernameValidation === 2) ? <Message success>Excelente, o nome de usuário é válido e está disponível!</Message> : null;
  const renderMessageErr = () => (usernameValidation === 1) ? <Message error>{usernameValidationMessage}</Message> : null;

  const usernameInput = () => {
    return (
      <>
        <label>Nome de usuário</label>
        <Input 
          fluid
          id="username" 
          required
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        ></Input>
        {renderMessageOk()}
        {renderMessageErr()}
      </>
    );
  };

  return (
    <Modal
      open={props.show}
      onClose={handleClose}
      size="tiny"
    >
      <Modal.Header>Alterar nome de usuário</Modal.Header>
      <Modal.Content>
        {usernameInput()}
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={handleClose}>Cancelar</Button>
        <Button color='teal' onClick={changeUsername}>Alterar</Button>
      </Modal.Actions>
    </Modal>
  )
};

export default ChangeUsernameDialog;