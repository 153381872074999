import { DateTime, Interval } from "luxon";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Container, Dimmer, Dropdown, DropdownProps, Grid, Header, Icon, Input, Label, Loader, Message, Pagination, PaginationProps, Popup, Segment, Select, Table } from "semantic-ui-react";
import { useDataguardVersoes, useFilterClientes, useStats } from "../../backend";
import { formataData, formataDataRelativa, formataTelefone, formataVersaoDataguard, formataVersaoDbmsAbreviada } from "../../helper";
import FilterData from "../../types/FilterData";
import Cliente from "../../types/models/Cliente";
import NovoClienteDialog from "../dialogs/NovoClienteDialog";

const ListClientes = () => {
  const navigate = useNavigate();
  const stats = useStats();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSearchTerm, setActiveSearchTerm] = useState('');
  const [pagina, setPagina] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [excluidos, setExcluidos] = useState(0);
  const [ativos, setAtivos] = useState(0);
  const [sortByColumn, setSortByColumn] = useState('nome');
  const [sortByColumnDirection, setSortByColumnDirection] = useState('asc');
  const [showNovoClienteDialog, setShowNovoClienteDialog] = useState(false);
  const params: FilterData = {
      sortBy: sortByColumn,
      sortOrder: sortByColumnDirection,
      pageNumber: pagina,
      pageSize: pageSize,
      ghosts: excluidos, 
      ativos: ativos,
      'extras.filtro': activeSearchTerm
  };

  const cliData = useFilterClientes(params);
  const vrsData = useDataguardVersoes();
  
  useEffect(() => {
    let intervalId = setInterval(() => {
      if (searchTerm !== activeSearchTerm) {
        setActiveSearchTerm(searchTerm);
      }
    }, 500);

    return () => clearInterval(intervalId);
  });

  const renderTableLoading = () => {
    return (
      <Grid container textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
        <Grid.Column>
          <Dimmer active inverted>
            <Loader size="massive" active></Loader>
          </Dimmer>
          <Message size="massive">{' '}</Message>
          <Message size="massive">Buscando dados dos clientes no servidor do DataGuard</Message>
        </Grid.Column>
      </Grid>  
    )
  };

  const renderTableError = () => {
    return (
    <Grid container textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
      <Grid.Column>
        <Icon name='dont' color="red" size="massive" />
        <Header as='h1'>Ocorreu um erro ao buscar os dados dos clientes no servidor</Header>
        <Message color='red'>Por favor atualize a página e tente novamente</Message>
      </Grid.Column>
    </Grid>
    );
  };

  const changeSort = (column: string) => {
    if (column === sortByColumn) {
      setSortByColumnDirection(sortByColumnDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortByColumn(column);
      setSortByColumnDirection('asc');
    }
  };

  const renderTableHead = () => {
    let opcao: ('ascending' | 'descending' | undefined) = (sortByColumnDirection === 'asc') ? 'ascending' : 'descending';
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell sorted={sortByColumn === 'id' ? opcao : undefined} onClick={() => changeSort('id')}>ID</Table.HeaderCell>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.HeaderCell>Últ. backup</Table.HeaderCell>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.HeaderCell>Versão</Table.HeaderCell>
          <Table.HeaderCell>SGBD</Table.HeaderCell>
          <Table.HeaderCell sorted={sortByColumn === 'username' ? opcao : undefined} onClick={() => changeSort('username')}>Usuário</Table.HeaderCell>
          <Table.HeaderCell sorted={sortByColumn === 'nome' ? opcao : undefined} onClick={() => changeSort('nome')}>Nome</Table.HeaderCell>
          <Table.HeaderCell>Contato</Table.HeaderCell>
          <Table.HeaderCell>Celular</Table.HeaderCell>
          <Table.HeaderCell>#</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  }

  const iconRunningStatus = (cli: Cliente) => {
    if (!cli.lastPing) return <Icon name='stop' color='red' />;
    else {
      const dtlp = DateTime.fromISO(cli.lastPing);
      const hora = Interval.fromDateTimes(dtlp, DateTime.now()).length('hours');
      if (hora > 2) return <Icon name='stop' color='red' />;
      else if (hora > 1) return <Icon name='pause' color='orange' />
      else return <Icon name='play' color='teal' />
    }
  };

  let versOk = ''; let versWarn = '';
  if (vrsData.data && vrsData.data?.length > 1) {
    versOk = vrsData.data[0]; versWarn = vrsData.data[1];
  }

  const labelVersaoDataGuard = (vrs: string | null | undefined): React.ReactNode => {
    let color: ('teal' | 'orange' | 'red') = (vrs && vrs === versOk) ? 'teal' : (vrs && vrs === versWarn) ? 'orange' : 'red';
    return (vrs && vrs?.length > 0) ? <Label basic size="mini" color={color}>{formataVersaoDataguard(vrs ?? '')}</Label> : null;
  };

  const renderTableRows = () => {
    return (
      <Table.Body>
        {cliData.data?.resultados.map((cli) => {
          return (
            <Table.Row key={cli.id}>
              <Table.Cell>{cli.id}</Table.Cell>
              <Table.Cell>{cli.ativo ? <Icon name='check' color='teal' /> : <Icon name='ban' color='red' /> }</Table.Cell>
              <Table.Cell><Popup content={formataData(cli.ultimoBackup)} trigger={<span>{formataDataRelativa(cli.ultimoBackup)}</span>} /></Table.Cell>
              <Table.Cell>{iconRunningStatus(cli)}</Table.Cell>
              <Table.Cell>{labelVersaoDataGuard(cli.versaoDataguard)}</Table.Cell>
              <Table.Cell>{formataVersaoDbmsAbreviada(cli.versaoDbms ?? '')}</Table.Cell>
              <Table.Cell>{cli.username}</Table.Cell>
              <Table.Cell>{cli.nome}</Table.Cell>
              <Table.Cell>{cli.contato}</Table.Cell>
              <Table.Cell>{formataTelefone(cli.celular ?? '')}</Table.Cell>
              <Table.Cell><NavLink to={`/clientes/${cli.id}`}><Icon name='eye' color='teal' /></NavLink></Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    )
  };

  const renderTableData = () => {
    return (
      <Table sortable striped compact basic>
        {renderTableHead()}
        {renderTableRows()}
      </Table>
    )
  }

  const renderTable = () => {
    if (!cliData || cliData.isLoading) return renderTableLoading();
    else if (cliData && cliData.isError) return renderTableError();
    else return renderTableData();
  }
  
  const renderStats = () => {
    if (!stats.data) return null;
    else return (
      <>
        <Button as='div' labelPosition="right">
          <Button icon color='teal'>
            <Icon name='address card' />&nbsp;&nbsp;Ativos
          </Button>
          <Label as='a' basic pointing='left'>{stats.data?.ativos}</Label>
        </Button>
        <Button as='div' labelPosition="right">
          <Button icon color='red'>
            <Icon name='user cancel' />&nbsp;&nbsp;Inativos
          </Button>
          <Label as='a' basic pointing='left'>{stats.data?.inativos}</Label>
        </Button>
      </>
    );
  };

  const handlePageChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, { activePage } : PaginationProps) => {
    let page: number = Number(activePage ?? 0);
    setPagina(page);
  }

  const handlePageSizeChange = (e: React.SyntheticEvent<HTMLElement, Event>, { value } : DropdownProps) => {
    let ps: number = Number(value ?? 0);
    setPageSize(ps);
  }

  const infoPagination = () => {
    let start: number = 1;
    let end: number = pageSize;

    if (pagina === 1) {
      start = 1; end = pageSize;
    } else {
      start = ((pagina - 1) * pageSize) + 1
      end = (pagina * pageSize);
    }
    
    if (cliData.data && end > cliData?.data?.contagemItens) end = cliData.data?.contagemItens;
    return 'Exibindo clientes ' + start + ' a ' + end + ' de ' + cliData.data?.contagemItens;
  }

  const renderFilterDropdown = () => {
    return (
      <Dropdown.Menu>
        <Dropdown.Header icon='tags' content='Ativos' />
        <Dropdown.Divider />
        <Dropdown.Item active={ativos === 0} onClick={(e) => setAtivos(0)}>Somente ativos</Dropdown.Item>
        <Dropdown.Item active={ativos === 1} onClick={(e) => setAtivos(1)}>Todos</Dropdown.Item>
        <Dropdown.Item active={ativos === 2} onClick={(e) => setAtivos(2)}>Somente inativos</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header icon='tags' content='Excluídos' />
        <Dropdown.Divider />
        <Dropdown.Item active={excluidos === 0} onClick={(e) => setExcluidos(0)}>Não mostrar os excluídos</Dropdown.Item>
        <Dropdown.Item active={excluidos === 1} onClick={(e) => setExcluidos(1)}>Incluir os excluídos</Dropdown.Item>
        <Dropdown.Item active={excluidos === 2} onClick={(e) => setExcluidos(2)}>Somente os excluídos</Dropdown.Item>
      </Dropdown.Menu>
    )
  }

  const renderControlBox = () => {
    if (!cliData || cliData.isLoading || cliData.isError) return null;
    return (
      <Grid.Column textAlign="right">
        <Button basic color="teal" onClick={() => setShowNovoClienteDialog(true)}>
          <Icon name='add' />
          Novo cliente
        </Button>
        <Dropdown button basic className='icon' icon='filter'>{renderFilterDropdown()}</Dropdown>
        <Input icon={{ name: 'search', circular: true, link: true }} placeholder="Pesquisar" onChange={(e) => setSearchTerm(e.target.value)} />
      </Grid.Column>
    );
  }

  const renderPageBottom = () => {
    if (!cliData || cliData.isLoading || cliData.isError) return null;
    return (
      <Grid.Row columns={3}>
        <Grid.Column width={3}>
          <Select 
            compact
            defaultValue={pageSize}
            onChange={handlePageSizeChange}
            options={[{ text: '30', value: 30 }, { text: '60', value: 60 }, { text: '100', value: 100 }]}
          ></Select>
          &nbsp;&nbsp;clientes por página
        </Grid.Column>
        <Grid.Column width={3} textAlign="center">
          <Message compact size="mini">{infoPagination()}</Message>
        </Grid.Column>
        <Grid.Column width={10}>
          <Pagination floated="right" onPageChange={handlePageChange} activePage={pagina} totalPages={cliData.data?.totalPaginas ?? 0}></Pagination>
        </Grid.Column>
      </Grid.Row>
    );
  };

  const closeNovoClienteCallback = (cli: Cliente | null) => {
    if (cli) {
      navigate(`/clientes/${cli.id}`);
    }
    setShowNovoClienteDialog(false);
  };

  return (
    <Container fluid>
    <Grid padded='horizontally'>
      <Grid.Column>
        <Segment>
          <Grid columns={2}>
            <Grid.Column>{renderStats()}</Grid.Column>
            {renderControlBox()}
          </Grid>
          <Grid>
            <Grid.Row>
            <Grid.Column>
              {renderTable()}
            </Grid.Column>
            </Grid.Row>
            {renderPageBottom()}
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid>
    <NovoClienteDialog show={showNovoClienteDialog} handleClose={closeNovoClienteCallback} />
    </Container>
  );
};

export default ListClientes;