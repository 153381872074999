import { Outlet } from "react-router-dom";
import { Container } from "semantic-ui-react";
import NavBar from "./Navbar"

const Layout = () => {
  return (
    <Container fluid>
      <NavBar />
      <Outlet />
    </Container>
  );
};

export default Layout;