import { DateTime, Interval } from "luxon";
import { Grid, Segment, Statistic } from "semantic-ui-react";
import Cliente from "../../types/models/Cliente";

const UltimoBackupCliente = (props: { cliente: Cliente | undefined }) => {
  if (!props.cliente) return null;

  let widgetColor : ('teal' | 'orange' | 'red') = 'teal';
  let text = 'INDISPONíVEL';

  if (props.cliente !== undefined){
    if (props.cliente.ultimoBackup) {
      const dtbk = DateTime.fromISO(props.cliente.ultimoBackup);
      const dias = Math.round(Interval.fromDateTimes(dtbk, DateTime.now()).length('days'));
      if (dias > 10) {
        widgetColor = 'red';
      } else if (dias > 5) {
        widgetColor = 'orange';
      } else {
        widgetColor = 'teal'
      }
      text = '' + dtbk.toRelative();
    }
  } 

  return (
    <Grid.Column textAlign="center">
      <Segment>
      <Statistic color={widgetColor} size="mini">
        <Statistic.Value>{text}</Statistic.Value>
        <Statistic.Label>ÚLTIMO BACKUP TRANSMITIDO</Statistic.Label>
      </Statistic>
      </Segment>
    </Grid.Column>
  );
};

export default UltimoBackupCliente;