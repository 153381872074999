import Cookies from 'js-cookie';
import axios from 'axios';
import useSWR from 'swr';
import PagedResult from '../types/PagedResult';
import AuthResponse from '../types/AuthResponse';
import ServerValidationResponse from '../types/ServerValidationResponse';
import Cliente from '../types/models/Cliente';
import StatusChangeRequest from '../types/StatusChangeRequest';
import FilterData from '../types/FilterData';
import SWRBackend from '../types/SWRBackend';
import Usuario from '../types/models/Usuario';
import Backup from '../types/models/Backup';
import UserStats from '../types/UserStats';
import ServerStats from '../types/ServerStats';
import MapaContagem from '../types/MapaContagem';
import useAuthStore from '../store/store';
import ValidTokenResponse from '../types/ValidTokenResponse';

const BASE_URL = 'https://dataguard.experttecnologia.com/api';

axios.interceptors.response.use(
  function (resp) {return resp;}, 
  function (err) {if (err.response.status === 401) { 
    const setUserId = useAuthStore((state) => state.setUserId);
    const setUserName = useAuthStore((state) => state.setUserName);
    const setApiToken = useAuthStore((state) => state.setApiToken);

    setUserId(0);
    setUserName('');
    setApiToken('');

    return Promise.reject(err);
}});

const backend = axios.create(
  {
    baseURL: BASE_URL,
    withCredentials: false,
    headers: {
      'Access-Control-Allow-Origin': '%',
      'Content-Type': 'application/json'
    }
  }
);

async function authenticate(username: string, password: string): Promise<AuthResponse | null> {
  try {
    const resp = await backend.post<AuthResponse>('/auth/authenticate', {username, password});
    return resp.data;
  }
  catch(error){ 
    if (axios.isAxiosError(error)) return null;
    else throw error;
  }
};
  
async function refreshToken(lastToken: string): Promise<AuthResponse | null> {
  try {
    const resp = await backend.get<AuthResponse>('/auth/refresh', { headers: { "Authorization": `Bearer ${lastToken}` } });
    return resp.data;
  }
  catch(error) {
    if(axios.isAxiosError(error)) return null;
    else throw error;
  }
};

async function validToken(): Promise<boolean> {
  try {
    const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}` } };
    const resp = await backend.get<ValidTokenResponse>('/auth/valid', params);
    return resp.data.id > 0;
  } catch (error) {
    return false;
  }
}

async function validateUsername(username: string): Promise<ServerValidationResponse | null> {
  try {
    const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}` }, params: { "dominio": 0, "valor": username } };
    const resp = await backend.get<ServerValidationResponse>('/cliente/validate/username', params);
    return resp.data;
  }
  catch(error) {
    if (axios.isAxiosError(error)) return null;
    else throw error;
  }
}

async function validateUsernameUpload(username: string): Promise<ServerValidationResponse | null> {
  try {
    const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}` }, params: { "dominio": 0, "valor": username } };
    const resp = await backend.get<ServerValidationResponse>('/cliente/validate/username/upload', params);
    return resp.data;
  }
  catch(error) {
    if (axios.isAxiosError(error)) return null;
    else throw error;
  }
}
  
async function changeUsername(id: number, username: string): Promise<Cliente | null> {
  try {
    const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}` } };
    const resp = await backend.put<Cliente>(`/cliente/username/change/${id}`, username, params);
    return resp.data;
  }
  catch(error) {
    if(axios.isAxiosError(error)) return null;
    else throw error;
  }
}

async function uploadFile(form: FormData): Promise<ServerValidationResponse | null> {
  try {
    const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}`, "Content-Type": 'multipart/form-data' } };
    const resp = await backend.post<ServerValidationResponse>(`/cliente/username/upload`, form, params);
    return resp.data;
  }
  catch(error) {
    if(axios.isAxiosError(error)) return null;
    else throw error;
  }
}

async function postCliente(cliente: Cliente): Promise<Cliente | null> {
  try {
    const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}` } };
    const resp = await backend.post<Cliente>('/cliente', cliente, params);
    return resp.data;
  }
  catch(error) {
    if(axios.isAxiosError(error)) return null;
    else throw error;
  }
}
  
async function putCliente(cliente: Cliente): Promise<Cliente | null> {
  try {
    const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}` } };
    const resp = await backend.put<Cliente>('/cliente', cliente, params);
    return resp.data;
  }
  catch(error) {
    if(axios.isAxiosError(error)) return null;
    else throw error;
  }
}

async function changeStatusCliente(statuschange: StatusChangeRequest): Promise<Cliente | null> {
  try {
    const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}` } };
    const resp = await backend.put<Cliente>('/cliente/statuschange', statuschange, params);
    return resp.data;
  }
  catch(error) {
    if(axios.isAxiosError(error)) return null;
    else throw error;
  }
}

const fetcher = async (url: string) => {
  const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}` } };
  return await backend.get(url, params).then((res) => res.data).catch(error => { if (error.response.status !== 409) throw error });
}

const fetcherWithParams = async (url: string, filterParams: FilterData) => {
  const params = { headers: { "Authorization": `Bearer ${Cookies.get('apiToken')}` }, params: filterParams };
  return await backend.get(url, params).then((res) => res.data).catch(error => { if (error.response.status !== 409) throw error });
}

const useUser = (id: number): SWRBackend<Usuario> => {
  const { data, error } = useSWR<Usuario>(`/usuario/${id}`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
};
  
const useCliente = (id: number): SWRBackend<Cliente> => {
  const { data, error } = useSWR<Cliente>(`/cliente/${id}`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
};

const useBackupsCliente = (idCliente: number): SWRBackend<Backup[]> => {
  const { data, error } = useSWR<Backup[]>(`/backup/cliente/${idCliente}`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}

const useLastBackups = (qtde: number): SWRBackend<Backup[]> => {
  const { data, error } = useSWR<Backup[]>(`/backup/${qtde}`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}  

const useClientesAtencao = (qtde: number): SWRBackend<Cliente[]> => {
  const { data, error } = useSWR<Cliente[]>(`/cliente/atencao/${qtde}`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
};
  
const useFilterClientes = (params: FilterData) => {
  const { data, error } = useSWR<PagedResult<Cliente>>([`/cliente/filter`, params], fetcherWithParams);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
};

const useStats = (): SWRBackend<UserStats> => {
  const { data, error } = useSWR<UserStats>(`/dataguard/stats`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
};
  
const useServerStats = (): SWRBackend<ServerStats> => {
  const { data, error } = useSWR<ServerStats>(`/dataguard/serverstats`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
};

const useDataguardVersoes = (): SWRBackend<string[]> => {
  const { data, error } = useSWR<string[]>(`/dataguard/versoes`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
};

const useMapaDataguardVersoes = (): SWRBackend<MapaContagem> => {
  const { data, error } = useSWR<MapaContagem>(`/dataguard/versoes/dataguard`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}

const useMapaDbmsVersoes = (): SWRBackend<MapaContagem> => {
  const { data, error } = useSWR<MapaContagem>(`/dataguard/versoes/dbms`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}

export { 
    authenticate, 
    validateUsername, 
    validateUsernameUpload, 
    changeUsername, 
    changeStatusCliente, 
    uploadFile,
    refreshToken, 
    validToken,
    fetcher, 
    useUser, 
    useCliente, 
    useFilterClientes, 
    useClientesAtencao,
    useBackupsCliente,
    useLastBackups,
    postCliente, 
    putCliente,
    useStats,
    useServerStats,
    useDataguardVersoes,
    useMapaDataguardVersoes,
    useMapaDbmsVersoes
  };