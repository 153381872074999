import { useEffect, useState } from "react";
import { Form, Grid, Message } from "semantic-ui-react";
import { validateUsername } from "../../backend";
import Cliente from "../../types/models/Cliente";

const FormCliente = (props: { cliente: Cliente | null, novo: boolean, formSubmitted: boolean, formCallback: (cli: Cliente | null) => void }) => {
  const [username, setUsername] = useState((props.cliente === undefined) ? '' : props.cliente?.username);
  const [debouncedUsername, setDebouncedUsername] = useState('');
  const [nome, setNome] = useState((props.cliente === undefined) ? '' : props.cliente?.nome);
  const [razaoSocial, setRazaoSocial] = useState((props.cliente === undefined) ? '' : props.cliente?.razaoSocial);
  const [celular, setCelular] = useState((props.cliente === undefined) ? '' : props.cliente?.celular);
  const [contato, setContato] = useState((props.cliente === undefined) ? '' : props.cliente?.contato);
  const [email, setEmail] = useState((props.cliente === undefined) ? '' : props.cliente?.email);
  const [observacoes, setObservacoes] = useState((props.cliente === undefined) ? '' : props.cliente?.observacoes);
  const [usernameValidation, setUsernameValidation] = useState(0);
  const [usernameValidationMessage, setUsernameValidationMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState<{[key:string]:string}>({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (debouncedUsername !== username) {
        setDebouncedUsername(username ?? '');
      }
    }, 500);

    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    if (debouncedUsername.length > 0) {
      validaUsername(debouncedUsername);
    }
  }, [debouncedUsername]);

  const validaUsername = async (u: string) => {
    if (!u) {
      setUsername(u);
      setUsernameValidation(1);
      setUsernameValidationMessage('O nome de usuário é obrigatório');
      return;
    }
    if (u.length < 3) {
      setUsername(u);
      setUsernameValidation(1);
      setUsernameValidationMessage('O nome de usuário deve ter ao menos 3 caracteres');
      return;
    }
    
    const srv = await validateUsername(u);
    if (srv) {
      setUsername(u);
      setUsernameValidation(srv.resposta);
      setUsernameValidationMessage(srv.mensagem);
    }
  };
  
  useEffect(() => {
    const validaNome = (e: {[key:string]: string}) => {
      if (!nome || nome.length === 0) {
        e = Object.assign(e, { nome: 'Este campo é de preenchimento obrigatório' });
        return false;
      } else if (nome.length < 3) {
        e = Object.assign(e, { nome: 'O campo de nome deve ter no mínimo 3 caracteres' });
        return false;
      }else if (nome.length > 100) {
        e = Object.assign(e, { nome: 'O campo de nome não pode ter mais de 100 caracteres' });
        return false;
      }
    };

    const validaRazaoSocial = (e: {[key:string]: string}) => {
      if (!razaoSocial || razaoSocial.length === 0) {
        e = Object.assign(e, { razaoSocial: 'Este campo é de preenchimento obrigatório' });
        return false;
      } else if (razaoSocial.length < 10) {
        e = Object.assign(e, { razaoSocial: 'O campo de razão social deve ter no mínimo 10 caracteres' });
        return false;
      }else if (razaoSocial.length > 100) {
        e = Object.assign(e, { razaoSocial: 'O campo de razão social não pode ter mais de 100 caracteres' });
        return false;
      }
    };

    const validaCelular = (e: {[key:string]: string}) => {
      if (!celular || celular.length === 0) return;
      else if (celular.length < 11) {
        e = Object.assign(e, { celular: 'O número de celular deve ter no mínimo 11 caracteres' });
        return false;
      }else if (celular.length > 11) {
        e = Object.assign(e, { celular: 'O número de celular não pode ter mais de 11 caracteres' });
        return false;
      }
    };

    const validaContato = (e: {[key:string]: string}) => {
      if (!contato || contato.length === 0) {
        e = Object.assign(e, { contato: 'Este campo é de preenchimento obrigatório' });
        return false;
      } else if (contato.length < 3) {
        e = Object.assign(e, { contato: 'O nome do contato deve ter no mínimo 3 caracteres' });
        return false;
      }else if (contato.length > 100) {
        e = Object.assign(e, { contato: 'O nome do contato não pode ter mais de 100 caracteres' });
        return false;
      }
    };

    const validaEmail = (e: {[key:string]: string}) => {
      if (!email || email.length === 0) {
        e = Object.assign(e, { email: 'Este campo é de preenchimento obrigatório' });
        return false;
      } else if (email.length < 3) {
        e = Object.assign(e, { email: 'O campo de nome deve ter no mínimo 3 caracteres' });
        return false;
      }else if (email.length > 100) {
        e = Object.assign(e, { email: 'O campo de nome não pode ter mais de 100 caracteres' });
        return false;
      }
    };
 
    if (props.formSubmitted) {
      if (props.novo) {
        const ssv = async () => {
          await validaUsername(username ?? '');
          if (usernameValidation !== 2) {
            props.formCallback(null);
            return false;
          } else return true;
        };

        if(!ssv()) return;
      }
      
      let erros = {};
      validaNome(erros);
      validaRazaoSocial(erros);
      validaCelular(erros);
      validaContato(erros);
      validaEmail(erros);

      if (Object.keys(erros).length !== 0) {
        setValidationErrors(erros);
        props.formCallback(null);
        return;
      }
      
      setValidationErrors(erros);
      
      let cliente: Cliente = {
        ativo: true,
        username: username ?? '',
        nome: nome ?? '',
        contato: contato ?? '',
        razaoSocial: razaoSocial ?? '',
        email: email ?? '',
        celular: celular,
        observacoes: observacoes
      }
      props.formCallback(cliente);
    }
  }, [celular, observacoes, contato, email, nome, props.novo, props.formCallback, props.formSubmitted, razaoSocial, username, usernameValidation, props]);
  
  const renderMessageUsernameOk = () => <Message success visible={usernameValidation === 2}>Excelente, o nome de usuário é válido e está disponível!</Message>;
  const renderMessageUsernameErr = () => <Message error visible={usernameValidation === 1}>{usernameValidationMessage}</Message>;

  const usernameInput = () => {
    if (!props.novo) return null;

    return (
      <Grid.Column>
        <Form.Input 
          label='Nome de usuário'
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        ></Form.Input>
        {renderMessageUsernameErr()}
        {renderMessageUsernameOk()}
      </Grid.Column>
    );
  };

  const nomeInput = () => {
    let error = false;
    if ('nome' in validationErrors) error = true;

    return (
      <Grid.Column>
        <Form.Input 
          label='Nome fantasia'
          required
          error={error}
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        ></Form.Input>
        <Message error compact size="tiny" visible={error}>{validationErrors['nome']}</Message>
      </Grid.Column>
    );
  };

  const razaoSocialInput = () => {
    let error = false;
    if ('razaoSocial' in validationErrors) error = true;

    return (
      <Grid.Column>
        <Form.Input 
          label='Razão Social'
          required
          minLength={10}
          error={error}
          value={razaoSocial}
          onChange={(e) => setRazaoSocial(e.target.value)}
        ></Form.Input>
        <Message error compact size="tiny" visible={error}>{validationErrors['razaoSocial']}</Message>
      </Grid.Column>
    );
  };

  const celularInput = () => {
    let error = false;
    if ('celular' in validationErrors) error = true;

    return (
      <Grid.Column>
        <Form.Input 
          label='Celular'
          error={error}
          value={celular}
          onChange={(e) => setCelular(e.target.value)}
        ></Form.Input>
        <Message error compact size="tiny" visible={error}>{validationErrors['celular']}</Message>
      </Grid.Column>
    );
  };

  const contatoInput = () => {
    let error = false;
    if ('contato' in validationErrors) error = true;

    return (
      <Grid.Column>
        <Form.Input 
          label='Contato'
          required
          error={error}
          value={contato}
          onChange={(e) => setContato(e.target.value)}
        ></Form.Input>
        <Message error compact size="tiny" visible={error}>{validationErrors['contato']}</Message>
      </Grid.Column>
    );
  };

  const emailInput = () => {
    let error = false;
    if ('email' in validationErrors) error = true;

    return (
      <Grid.Column >
        <Form.Input 
          label='Email'
          required
          error={error}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></Form.Input>
        <Message error compact size="tiny" visible={error}>{validationErrors['email']}</Message>
      </Grid.Column>
    );
  };

  const observacoesInput = () => {
    let error = false;
    if ('observacoes' in validationErrors) error = true;

    return (
      <Grid.Column>
        <Form.TextArea 
          label='Observações'
          error={error}
          value={observacoes}
          onChange={(e) => setObservacoes(e.target.value)}
        ></Form.TextArea>
        <Message error compact size="tiny" visible={error}>{validationErrors['observacoes']}</Message>
      </Grid.Column>
    );
  };

  return (
    <Form>
      <Grid>
        <Grid.Row stretched>
          {usernameInput()}
        </Grid.Row>
        <Grid.Row stretched>
          {nomeInput()}
        </Grid.Row>
        <Grid.Row stretched>
          {razaoSocialInput()}
        </Grid.Row>
        <Grid.Row columns={2} stretched>
          {celularInput()}
          {contatoInput()}
        </Grid.Row>
        <Grid.Row stretched>
          {emailInput()}
        </Grid.Row>
        <Grid.Row>
          {observacoesInput()}
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default FormCliente;