import Cookies from "js-cookie";
import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Grid, Header, Menu, Segment, Icon, Dropdown } from "semantic-ui-react";
import { refreshToken } from "../backend";
import useAuthStore from "../store/store";

const NavBar = () => {
  const apiToken = useAuthStore((state) => state.apiToken);
  const userName = useAuthStore((state) => state.userName);
  const setApiToken = useAuthStore((state) => state.setApiToken);
  const setUserId = useAuthStore((state) => state.setUserId);
  const setUserName = useAuthStore((state) => state.setUserName);
  const setSessionLength = useAuthStore((state) => state.setSessionLength);

  let location = useLocation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const resp = refreshToken(apiToken);
      resp.then((auth) => {
        setApiToken(auth?.jwtToken ?? '');
        setUserId(auth?.id ?? 0);
        setUserName(auth?.name ?? '');
        setSessionLength(auth?.sessionLength ?? 0);
        Cookies.set('apiToken', auth?.jwtToken ?? '');
      }).catch(() => {
        setApiToken('');
        setUserId(0);
        setUserName('');
        setSessionLength(0);
        Cookies.set('apiToken', '');
      });
    }, 10 * 60000);

    return () => clearInterval(intervalId);
  });

  const logout = () => {
    setApiToken('');
    setUserId(0);
    setUserName('');
    setSessionLength(0);
  };

  return (
    <Grid padded>
      <Grid.Column>
      <Segment padded>
      <Menu pointing secondary>
        <Menu.Item name='brand'><Header as='h4'>DATAGUARD WEB</Header></Menu.Item>
        <Menu.Item name='home' active={location.pathname === '/'} color="teal">
          <NavLink to='/' style={{ color: 'teal' }}>HOME</NavLink>
        </Menu.Item>
        <Menu.Item name='clientes' active={location.pathname.startsWith('/clientes')} color="teal">
          <NavLink to='/clientes' style={{ color: 'teal' }}>CLIENTES</NavLink>
        </Menu.Item>
        <Menu.Item name='upload' active={location.pathname.startsWith('/upload')} color="teal">
          <NavLink to='/upload' style={{ color: 'teal' }}>UPLOAD</NavLink>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item name='usuario'>
            <Icon name="user" />
            <Dropdown text={userName}>
              <Dropdown.Menu>
                <Dropdown.Item><NavLink to='/perfil'>Perfil</NavLink></Dropdown.Item>
                <Dropdown.Item><NavLink to='/sobre'>Sobre</NavLink></Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item><NavLink to='' onClick={() => logout()}>Sair</NavLink></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default NavBar;