import { useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Message, Segment } from "semantic-ui-react";
import { uploadFile, validateUsernameUpload } from "../backend";

const Upload = () => {
  const [username, setUsername] = useState('');
  const [debouncedUsername, setDebouncedUsername] = useState('');
  const [file, setFile] = useState<File | undefined | null>();
  const [fileInputKey, setFileInputKey] = useState(0);
  const [usernameValidation, setUsernameValidation] = useState(0);
  const [usernameValidationMessage, setUsernameValidationMessage] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');
  const [showMessageOk, setShowMessageOk] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{[key:string]:string}>({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (username !== debouncedUsername) setDebouncedUsername(username);
    }, 500);

    return () => clearInterval(intervalId);
  })

  useEffect(() => {
    if (debouncedUsername.length > 0) {
      validaUsername(debouncedUsername);
    }
  }, [debouncedUsername]);
  
  const handleUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file) {
      let fileVal = Object.assign(validationErrors, { file: 'Este campo é de preenchimento obrigatório' });
      setValidationErrors(fileVal);
      return;
    }

    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('username', username);

      const up = await uploadFile(formData);
      if (up && up.resposta === 2) {
        setShowMessageOk(true);
        setUsername('');
        setFile(null);
        setFileInputKey(fileInputKey + 1);
        setUsernameValidation(0);
        setUsernameValidationMessage(''); 
      } else {
        setUploadMessage(up?.mensagem ?? 'Falha ao realizar o upload');
        setShowMessageError(true);
      }      
    }
  }

  const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = e.target.files;
    if (filesList && filesList.length > 0) {
      setFile(filesList[0]);
    }
  }

  const validaUsername = async (u: string) => {
    if (!u) {
      setUsername(u);
      setUsernameValidation(1);
      setUsernameValidationMessage('O nome de usuário é obrigatório');
      return;
    }
    if (u.length < 3) {
      setUsername(u);
      setUsernameValidation(1);
      setUsernameValidationMessage('O nome de usuário deve ter ao menos 3 caracteres');
      return;
    }
    
    const srv = await validateUsernameUpload(u);
    if (srv) {
      setUsername(u);
      setUsernameValidation(srv.resposta);
      setUsernameValidationMessage(srv.mensagem);
    }
  };

  const renderMessageUsernameOk = () => <Message success visible={usernameValidation === 2}>Excelente, o nome de usuário é válido e está disponível!</Message>;
  const renderMessageUsernameErr = () => <Message error visible={usernameValidation === 1}>{usernameValidationMessage}</Message>;

  const usernameInput = () => {
    return (
      <Grid.Column>
        <Form.Input 
          label='Nome de usuário'
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        ></Form.Input>
        {renderMessageUsernameErr()}
        {renderMessageUsernameOk()}
      </Grid.Column>
    );
  };

  const fileInput = () => {
    let error = false;
    if ('file' in validationErrors) error = true;

    return (
      <Grid.Column>
        <Form.Input 
          type="file"
          id="filenameInput"
          name="filenameInput"
          multiple={false}
          key={fileInputKey}
          onChange={selectFile}
        ></Form.Input>
        <Message error compact size="tiny" visible={error}>{validationErrors['file']}</Message>
      </Grid.Column>
    );
  };

  const renderUploadOk = () => {
    if (!showMessageOk) return null;
    else return (
      <Message attached='bottom' success>
        <Icon name='ban' />
        {uploadMessage}
      </Message>
    )
  };

  const renderUploadError = () => {
    if (!showMessageError) return null;
    else return (
      <Message attached='bottom' error>
        <Icon name='bug' />
        {uploadMessage}
      </Message>
    )
  };
  
  return (
    <Grid.Row>
        <Grid columns={1} padded='horizontally'>
          <Grid.Column>
          <Segment>
            <Form onSubmit={handleUpload}>
              <Grid>
                <Grid.Row stretched>
                  {usernameInput()}
                </Grid.Row>
                <Grid.Row stretched>
                  {fileInput()}
                </Grid.Row>
              </Grid>
              <Grid.Row>
                <Grid.Column>
                  <Button type="submit" color="teal">Enviar arquivo</Button>
                </Grid.Column>
              </Grid.Row>
            </Form>
            {renderUploadOk()}
            {renderUploadError()}
          </Segment>
        </Grid.Column>
      </Grid>
    </Grid.Row>
  );
};

export default Upload;