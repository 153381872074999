import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface AuthState {
  userId: number;
  userName: string;
  apiToken: string;
  sessionLength: number;

  setUserId: (id: number) => void;
  setUserName: (name: string) => void;
  setApiToken: (token: string) => void;
  setSessionLength: (length: number) => void;
};

const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        userId: 0,
        setUserId: (id: number) => set((state: AuthState) => ({ userId: id })),
        userName: '',
        setUserName: (name: string) => set((state: AuthState) => ({ userName: name })),
        apiToken: '',
        setApiToken: (token: string) => set((state: AuthState) => ({ apiToken: token })),
        sessionLength: 0,
        setSessionLength: (length: number) => set((state: AuthState) => ({ sessionLength: length }))
      }),
      {
        name: 'auth-storage'
      }
    )
  )
);

export default useAuthStore;
