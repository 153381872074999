import { DateTime, Interval } from 'luxon';
import { Link } from 'react-router-dom';
import { Grid, Header, Label, Loader, Message, Popup, Segment, Table } from 'semantic-ui-react';
import { useClientesAtencao } from '../../backend';
import { formataData, formataDataRelativa } from '../../helper';

const ClientesAtencao = () => {
  const { data, isLoading, isError } = useClientesAtencao(15);
  
  const loadingJSX = () => {
    return (
      <Segment>
        <Grid columns={1}>
          <Grid.Column>
            <Loader >Carregando</Loader>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  const erroJSX = () => {
    return (
      <Segment>
        <Grid column={1}>
          <Grid.Column>
            <Message error visible>Falha ao carregar dados</Message>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  const colorAtraso = (ultimoBkp: string | undefined): ( 'red' | 'orange' | 'teal' ) => {
    if (!ultimoBkp) return 'red';

    const dtbk = DateTime.fromISO(ultimoBkp);
    const dias = Math.round(Interval.fromDateTimes(dtbk, DateTime.now()).length('days'));

    if (dias > 10) return 'red';
    else if (dias > 5) return 'orange';
    else return 'teal';
  }

  const tabelaClientes = () => {
    return (
      <>
        <Header textAlign='center' as='h5' color='orange'>Clientes necessitando de atenção</Header>
        <Table striped compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Ultimo Backup</Table.HeaderCell>
              <Table.HeaderCell>Username</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.map((cli) => {
              return (
                <Table.Row key={cli.id}>
                  <Table.Cell>{cli.id}</Table.Cell>
                  <Table.Cell><Popup content={formataData(cli.ultimoBackup)} trigger={<Label size='tiny' basic color={colorAtraso(cli.ultimoBackup)}>{formataDataRelativa(cli.ultimoBackup)}</Label>} /></Table.Cell>
                  <Table.Cell>{cli.username}</Table.Cell>
                  <Table.Cell><Link to={`/clientes/${cli.id}`}>{cli.nome}</Link></Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
  
  return (
    <Segment color='orange'>
      {isLoading ? loadingJSX() : (isError) ? erroJSX() : tabelaClientes()}
    </Segment>
  );  
};

export default ClientesAtencao;