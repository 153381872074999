import { Link } from 'react-router-dom';
import { Grid, Header, Loader, Message, Popup, Segment, Table } from 'semantic-ui-react';
import { useLastBackups } from '../../backend';
import { formataData, formataDataRelativa, humanFileSize } from '../../helper';

const UltimosBackups = () => {
  const { data, isLoading, isError } = useLastBackups(15);
  
  const loadingJSX = () => {
    return (
      <Segment>
        <Grid columns={1}>
          <Grid.Column>
            <Loader >Carregando</Loader>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  const erroJSX = () => {
    return (
      <Segment>
        <Grid column={1}>
          <Grid.Column>
            <Message error visible>Falha ao carregar dados</Message>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  const tabelaBackups = () => {
    return (
      <>
        <Header textAlign='center' as='h5' color='teal'>Últimos backups recebidos no servidor</Header>
        <Table striped compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data/Hora</Table.HeaderCell>
              <Table.HeaderCell>Username</Table.HeaderCell>
              <Table.HeaderCell>Arquivo</Table.HeaderCell>
              <Table.HeaderCell>Tamanho</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data?.map((bkp) => {
              return (
                <Table.Row key={bkp.id}>
                  <Table.Cell><Popup content={formataData(bkp.data)} trigger={<span>{formataDataRelativa(bkp.data)}</span>} /></Table.Cell>
                  <Table.Cell><Link to={`/clientes/${bkp.clienteId}`}>{bkp.username}</Link></Table.Cell>
                  <Table.Cell>{bkp.filename}</Table.Cell>
                  <Table.Cell textAlign='right'>{humanFileSize(bkp.size)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
  
  return (
    <Segment color='teal'>
      {isLoading ? loadingJSX() : (isError) ? erroJSX() : tabelaBackups()}
    </Segment>
  );  
};

export default UltimosBackups;