import { useState } from "react";
import { Button, Form, Header, Message, Modal, TextArea } from "semantic-ui-react";
import { StatusChangeType } from "../../types/StatusChangeRequest";

const StatusChangeDialog = (props: { show: boolean, operacao: StatusChangeType, children: React.ReactNode, handleClose: (sst: StatusChangeType, m: string | null) => void}) => {
  const [motivo, setMotivo] = useState('');
  const [stage, setStage] = useState(0);
  
  const handleClose = () => {
    setStage(0);
    setMotivo('');
    props.handleClose(props.operacao, null);
  };

  let cssColorButton: ('red' | 'green' | 'blue') = 'red';
  let strOpr = '';
  switch(props.operacao) {
    case 0: // Reativar
      strOpr = 'Reativar';
      cssColorButton = 'blue';
      break;
    case 1: // Inativar
      strOpr = 'Inativar';
      cssColorButton = 'red';
      break;
    case 2: // Restaurar
      strOpr = 'Restaurar';
      cssColorButton = 'green';
      break;
    case 3: // Excluir
      strOpr = 'Excluir';
      cssColorButton = 'red';
      break;
  }

  const submitStatusChange = () => {
    if (stage === 0) {
      setStage(1);
      setTimeout(() => {
      }, 500);
    } else {
      if (motivo.trim().length === 0) {
        setStage(2);
        return;
      }
  
      props.handleClose(props.operacao, motivo);
      setStage(0);
      setMotivo('');
    }
  };

  const showFormError = () => {
    if (stage === 2) {
      return (
        <Message error textAlign='start'>
        <Message.List>
          <Message.Item>
            O motivo é de preenchimento obrigatório
          </Message.Item>
        </Message.List>
      </Message>
      );
    } else return null;
  }

  const motivoInput = () => {
    if (stage === 0) return null;
    return (
      <div>
        <p>Informe abaixo o motivo para {strOpr.toLowerCase()} este cadastro</p>
        <Form>
            <TextArea
              required
              autoFocus
              value={motivo}
              minLength={3}
              onChange={(e) => setMotivo(e.target.value)}
            ></TextArea>
          </Form>
          {showFormError()}
      </div>
    );
  }

  return (
    <Modal
      onClose={() => handleClose}
      open={props.show}
      size="tiny"
    >
      <Modal.Header>{strOpr}</Modal.Header>
      <Modal.Content>
        <Header as='h4'>Tem certeza que deseja {strOpr.toLowerCase()} o cadastro deste cliente ?</Header>
        {props.children}
        {motivoInput()}
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={handleClose}>Cancelar</Button>
        <Button color={cssColorButton} onClick={submitStatusChange}>
          {stage === 0 ? 'Sim, desejo ' + strOpr.toLowerCase() : strOpr }
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default StatusChangeDialog;