import { Button, Grid, Header, Label, Segment } from "semantic-ui-react";
import { useMapaDbmsVersoes } from "../../backend";

const MapaVersoesDbms = () => {
  const dbmsMap = useMapaDbmsVersoes();

  const nomeDbms = (v: string): string => {
    switch(v) {
      case '00.00': return 'Desconhecida';
      case '10.0E': return '2008 Express';
      case '10.5E': return '2008 R2 Express';
      case '11.0E': return '2012 Express';
      case '12.0E': return '2014 Express';
      case '13.0E': return '2016 Express';
      case '14.0E': return '2017 Express';
      case '15.0E': return '2019 Express';
      case '16.0E': return '2022 Express';
      case '10.0F': return '2008 Full';
      case '10.5F': return '2008 R2 Full';
      case '11.0F': return '2012 Full';
      case '12.0F': return '2014 Full';
      case '13.0F': return '2016 Full';
      case '14.0F': return '2017 Full';
      case '15.0F': return '2019 Full';
      case '16.0F': return '2022 Full';
      default: return '----';
    }
  };

  const colorVersao = (v: string) : ('teal' | 'orange' | 'red') => {
    switch(v) {
      case '00.00': return 'red';
      case '10.0E': return 'red';
      case '10.5E': return 'red';
      case '11.0E': return 'red';
      case '12.0E': return 'orange';
      case '13.0E': return 'orange';
      case '14.0E': return 'orange';
      case '15.0E': return 'teal';
      case '16.0E': return 'teal';
      case '10.0F': return 'red';
      case '10.5F': return 'red';
      case '11.0F': return 'red';
      case '12.0F': return 'orange';
      case '13.0F': return 'orange';
      case '14.0F': return 'orange';
      case '15.0F': return 'teal';
      case '16.0F': return 'teal';
      default: return 'red';
    }
  }

  let versDataDbms: {x: string, y: any, z: string}[] = [];
  dbmsMap.data?.mapa.forEach((m) => {
    versDataDbms.push({ x: nomeDbms(m.v), y: m.c, z: m.v });
  });

  return (
    <Grid.Column stretched textAlign="center">
      <Segment textAlign="left" >
      <Header as='h5'>Versões SGBD</Header>
      <Grid columns={1}>
        {versDataDbms.map((v: {x:string, y:any, z:string}) => {
          return (
            <Grid.Column key={v.z}>
              <Button as='div' labelPosition='right'>
                  <Button size="tiny" color={colorVersao(v.z)}>{v.x}</Button>
                  <Label size="tiny" color={colorVersao(v.z)} basic pointing='left'>{v.y}</Label>
              </Button>
            </Grid.Column>
          )
        })}
        </Grid>
      </Segment>
    </Grid.Column>
  )
};

export default MapaVersoesDbms; 