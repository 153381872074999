import { DateTime } from 'luxon';

export function humanFileSize(bytes: number, si: boolean = true, dp: number = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
};

export function formataVersaoDataguard(versao: string) {
    if (!versao) return '';
    else {
        if (versao.length === 8) {
            return versao.substring(4, 8);
        } else {
            return versao;
        }
    }
};

export function formataVersaoDbmsAbreviada(versao: string) {
  if (!versao) return '';
  if (versao === '') return '';  

  if (versao.startsWith("10.0")) return "SQL 2008";
  else if (versao.startsWith("10.50")) return "SQL 2008 R2";
  else if (versao.startsWith("11.0")) return "SQL 2012";
  else if (versao.startsWith("12.0")) return "SQL 2014";
  else if (versao.startsWith("13.0")) return "SQL 2016";
  else if (versao.startsWith("14.0")) return "SQL 2017";
  else if (versao.startsWith("15.0")) return "SQL 2019";
  else if (versao.startsWith("16.0")) return "SQL 2022";
  else return versao;
};

export function formataVersaoDbmsCompleta(versao: string) {
  if (!versao) return '';
  if (versao === '') return '';

  var sufixo = "";
  if (versao.endsWith("E")) sufixo = " Express";
  else if (versao.endsWith("F")) sufixo = " Full";
  
  var vnum = versao.substring(0, versao.length - 1);
  var fver = formataVersaoDbmsAbreviada(versao);
  fver += " (" + vnum + ") " + sufixo;
  return fver.trim();
};

export function formataTelefone(value: string) {
  if (!value) {
    return '';
  } else if (value.length === 7) {
      return value.substring(0, 3) + ' - ' + value.substring(3, 7);
  } else if (value.length === 8) {
      return value.substring(0, 4) + ' - ' + value.substring(4, 8);
  } else if (value.length === 9) {
      if (value.substring(0, 1) === '9') {
          return value.substring(0, 1) + '.' + value.substring(1, 5) + ' - ' + value.substring(5, 9);
      } else {
          return value.substring(0, 5) + ' - ' + value.substring(5, 9);
      }
  } else if (value.length === 10) {
      return '(' + value.substring(0, 2) + ') ' + value.substring(2, 6) + ' - ' + value.substring(6, 10);
  } else if (value.length === 11) {
      if (value.substring(2, 3) === '9') {
          return (
              '(' +
              value.substring(0, 2) +
              ') ' +
              value.substring(2, 3) +
              '.' +
              value.substring(3, 7) +
              ' - ' +
              value.substring(7, 11)
          );
      } else {
          return '(' + value.substring(0, 2) + ') ' + value.substring(2, 7) + ' - ' + value.substring(7, 11);
      }
  } else {
      return value;
  }
};

export function formataData(data: string | null | undefined): string {
    return DateTime.fromISO(data ?? '').toLocaleString(DateTime.DATETIME_SHORT);
}

export function formataDataUTC(data: string | null | undefined): string {
  return DateTime.fromISO(data + 'Z' ?? '').toLocaleString(DateTime.DATETIME_SHORT);
}

export function formataDataRelativa(data: string | null | undefined): string {
  const ret = DateTime.fromISO(data ?? '').toRelative();
  return ret ?? '';
}

export function formataDataRelativaUTC(data: string | null | undefined): string {
  const ret = DateTime.fromISO(data + 'Z' ?? '').toRelative();
  return ret ?? '';
}
