import { Grid, Segment, Statistic } from "semantic-ui-react";
import { useStats } from "../../backend";
import { humanFileSize } from "../../helper";

const BackupsUsuario = () => {
    const userData = useStats();

    if (userData.data === null) return null;
    if (userData.data === undefined) return null;
    if (userData.isLoading) return null;
    if (userData.isError) return null;

    return (
      <>
      <Grid.Column textAlign = "center">
        <Segment>
          <Statistic color='teal' size="mini">
            <Statistic.Value>{userData.data.ativos}</Statistic.Value>
            <Statistic.Label>CLIENTES ATIVOS</Statistic.Label>
          </Statistic>
        </Segment>
      </Grid.Column>
      {/* <Grid.Column textAlign = "center">
        <Segment>
          <Statistic color='orange' size="mini">
            <Statistic.Value>{userData.data.inativos}</Statistic.Value>
            <Statistic.Label>CLIENTES INATIVOS</Statistic.Label>
          </Statistic>
        </Segment>
      </Grid.Column> */}
      <Grid.Column textAlign = "center">
        <Segment>
          <Statistic color='blue' size="mini">
            <Statistic.Value>{userData.data.qtdeBackups}</Statistic.Value>
            <Statistic.Label>BACKUPS ( {humanFileSize(userData.data.tamanhoBackups)} )</Statistic.Label>
          </Statistic>
        </Segment>
      </Grid.Column>
      </>
  );
};

export default BackupsUsuario;