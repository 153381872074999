import { useState } from "react";
import { Button, Label, Modal, Progress } from "semantic-ui-react";
import { postCliente } from "../../backend";
import Cliente from "../../types/models/Cliente";
import FormCliente from "../clientes/FormCliente";

const NovoClienteDialog = (props: { show: boolean, handleClose: (cli: Cliente | null) => void}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
    
  const handleClose = () => {
    props.handleClose(null);
  };

  const submitForm = () => {
    setFormSubmitted(true);
  };

  const formCallback = async (formData: Cliente | null) => {
    setFormSubmitted(false);

    if (formData) {
      await enviaCliente(formData);
    }    
  };

  const enviaCliente = async (cliente: Cliente) => {
    const cli = await postCliente(cliente);
    setFormSubmitted(false);
    props.handleClose(cli);
  };

  const renderCardBottom = () => {
    if (formSubmitted) {
      return (
        <>
          <Progress percent={100} indicating />
          <Label as='h4' color="green">Enviando informações do cliente para o servidor ...</Label>
        </>
      );
    } else {
      return <Button color='teal' onClick={submitForm}>Salvar alterações</Button>;
    }
  };

  return (
    <Modal
      open={props.show}
      onClose={handleClose}
      size="tiny"
    >
      <Modal.Header>Editar cadastro de cliente</Modal.Header>
      <Modal.Content>
        <FormCliente
          novo
          cliente={null}
          formSubmitted={formSubmitted}
          formCallback={formCallback}
        ></FormCliente>
      </Modal.Content>
      <Modal.Actions>
        {renderCardBottom()}
      </Modal.Actions>
    </Modal>
  )
}

export default NovoClienteDialog;