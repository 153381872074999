import { Grid, Popup, Progress, Segment, Statistic }
from "semantic-ui-react";
import { useServerStats } from "../../backend";
import { humanFileSize } from "../../helper";

const ServerStats = () => {
    const servData = useServerStats();

    if (servData.data === null) return null;
    if (servData.data === undefined) return null;
    if (servData.isLoading) return null;
    if (servData.isError) return null;

    let percDisk = (servData.data.freeSpace * 100) / servData.data.diskSize;
    percDisk = 100 - percDisk;
    percDisk = Math.round(percDisk);

    return (
      <>
      <Grid.Column textAlign = "center">
        <Segment>
          <Statistic color='teal' size="mini">
            <Statistic.Value>{servData.data.qtdeBackups}</Statistic.Value>
            <Statistic.Label>SERVIDOR ( {humanFileSize(servData.data.tamanhoBackups)} )</Statistic.Label>
          </Statistic>
        </Segment>
      </Grid.Column>
      <Grid.Column textAlign = "center">
        <Segment style={{ height: '100%' }}>
          <Popup 
            content={humanFileSize(servData.data.freeSpace) + ' disponíveis de ' + humanFileSize(servData.data.diskSize)}
            trigger={<Progress percent={percDisk} size="small" color='teal' label={'Uso do disco: ' + percDisk + ' %'} />}
          />
        </Segment>
      </Grid.Column>
      </>
  );
};

export default ServerStats;