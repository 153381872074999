import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import ClienteDetail from './components/clientes/ClienteDetail';
import ListClientes from './components/clientes/ListClientes';
import GuardedRoute from './components/GuardedRoute';
import Home from './components/Home';
import Layout from './components/Layout';
import Login from './components/Login';
import Upload from './components/Upload';
import useAuthStore from './store/store';

function App() {
  const userId = useAuthStore((state) => state.userId);

  if (userId && userId > 0) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<GuardedRoute><Home /></GuardedRoute>} />
            <Route path="/upload" element={<GuardedRoute><Upload /></GuardedRoute>} />
            <Route path="/clientes" element={<GuardedRoute><ListClientes /></GuardedRoute>} />
            <Route path="/clientes/:id" element={<GuardedRoute><ClienteDetail /></GuardedRoute>} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  } else {
    return <Container><Login /></Container>;
  }
}

export default App;
