import { Grid, Segment, Statistic } from "semantic-ui-react";
import { useDataguardVersoes } from "../../backend";
import Cliente from "../../types/models/Cliente";

const VersaoDataGuardCliente = (props: { cliente: Cliente | undefined }) => {
  const vers = useDataguardVersoes();

  if (!props.cliente) return null;

  let versOk = ''; let versWarn = '';
  if (vers.data && vers.data?.length > 1) {
    versOk = vers.data[0]; versWarn = vers.data[1];
  }

  let vrsdgw = props.cliente.versaoDataguard ?? 'DESCONHECIDA'; 
  let widgetColor: ('teal' | 'orange' | 'red') = (vrsdgw === versOk) ? 'teal' : (vrsdgw === versWarn) ? 'orange' : 'red';

  if (vrsdgw.length === 0) vrsdgw = 'DESCONHECIDA';
  return (
    <Grid.Column textAlign="center">
      <Segment>
      <Statistic color={widgetColor} size="mini">
        <Statistic.Value>{vrsdgw}</Statistic.Value>
        <Statistic.Label>VERSÃO DATAGUARD</Statistic.Label>
      </Statistic>
      </Segment>
    </Grid.Column>
  );
};

export default VersaoDataGuardCliente;