import { Grid, Segment, Statistic } from "semantic-ui-react";
import Cliente from "../../types/models/Cliente";

const StatusCliente = (props: { cliente: Cliente | undefined }) => {
  if (!props.cliente) return null;
  return (
    <Grid.Column textAlign="center">
      <Segment>
      <Statistic color={props.cliente?.ativo ? 'teal' : 'red'} size="mini">
        <Statistic.Value>{props.cliente?.ativo ? 'ATIVO' : 'INATIVO'}</Statistic.Value>
        <Statistic.Label>STATUS</Statistic.Label>
      </Statistic>
      </Segment>
    </Grid.Column>
  );
};

export default StatusCliente;