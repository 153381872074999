import { Container, Grid } from "semantic-ui-react";
import BackupsUsuario from "./widgets/BackupsUsuario";
import ClientesAtencao from "./widgets/ClientesAtencao";
import MapaVersoesDbms from "./widgets/MapaVersoesDbms";
import MapaVersoesDgw from "./widgets/MapaVersoesDgw";
import ServerStats from "./widgets/ServerStats";
import UltimosBackups from "./widgets/UltimosBackups";

const Home = () => {
  return (
    <Container fluid>
      <Grid.Row>
        <Grid columns={6} centered>
          <BackupsUsuario />
          <ServerStats />
          <MapaVersoesDgw />
        </Grid>
      </Grid.Row>
      <Grid.Row columns={10}>
        <Grid padded='horizontally'>
          <Grid.Column width={7}>
            <ClientesAtencao />
          </Grid.Column>
          <Grid.Column width={7}>
            <UltimosBackups />
          </Grid.Column>
          <Grid.Column width={2}>
            <MapaVersoesDbms />
          </Grid.Column>
        </Grid>
      </Grid.Row>
    </Container>
  );
};

export default Home;